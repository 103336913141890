import { useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { OptVerification } from "../components/OptVerification";
import { PaymentsSetup } from "../modules/PaymentsSetup";
import { SetupPayoutSettings } from "../modules/SetupPayoutSettings";
import { WSQueries } from "../query/WSQuery";
import { useUserId } from "../query/hooks/helpers";
import { usePaymentsStatusQuery } from "../query/payments/queries";
import { useSession } from "../query/session";
import {
  useActivities,
  useMemberProfile,
  useUserProfile
} from "../query/users/queries";
import { pushSessionToken } from "../services/sessionStorage";
import { ModalPayoutAccounts } from "../shared/components/ModalPayoutAccounts";
import { selectorPayoutMethodsFromQueryParam } from "../shared/selectors/selectorPayoutMethodsFromQueryParam";
import { PayoutMethodType } from "../shared/types/payments";
import { sendMessageToParent } from "../utils/embedded";
import { loadFullStory } from "../utils/fullStory";
import { MemberPlaidModals } from "../utils/usePlaidLink";
import { OnboarderComplete } from "./OnboarderComplete";

export const Onboarder = () => {
  const [skipTaxVerification, setSkipTaxVerification] = useState<
    boolean | undefined
  >();
  const [availablePayoutMethods, setAvailablePayoutMethods] = useState<
    PayoutMethodType[] | undefined
  >();

  useEffect(() => {
    loadFullStory();
    // Load analytics
    // loadSegment();

    // Parse url params on component mount
    const urlSearchParams = new URLSearchParams(window.location.search);
    const token = urlSearchParams.get("token");
    if (token) {
      pushSessionToken(token as string);
    }

    const tinParam = urlSearchParams.get("tin");
    const enabledPayoutMethodsParam = urlSearchParams.get(
      "enabled_payout_methods"
    );

    if (tinParam === "0") {
      setSkipTaxVerification(true);
    }

    if (enabledPayoutMethodsParam) {
      setAvailablePayoutMethods(
        selectorPayoutMethodsFromQueryParam(enabledPayoutMethodsParam)
      );
    }
  }, []);

  const [isInitialRedirect, setIsInitialRedirect] = useState(true);

  const userId = useUserId();
  const history = useHistory();

  const qSession = useSession({
    onError: error => {
      try {
        const errorPayload = {
          axiosError: error,
          response: error.response
        };
        sendMessageToParent("errorLoadingSession", {
          error: errorPayload
        });
      } catch {
        sendMessageToParent("errorLoadingSession", {
          error: { message: "No error message available" }
        });
      }
    },
    onSuccess: data => sendMessageToParent("sessionLoaded")
  });
  const qUserProfile = useUserProfile(userId, { enabled: qSession.isSuccess });
  const qMemberProfile = useMemberProfile(userId, {
    enabled: qSession.isSuccess
  });
  const qActivities = useActivities(userId, {
    enabled: qSession.isSuccess
  });
  const qPaymentsStatus = usePaymentsStatusQuery({
    enabled: qSession.isSuccess
  });

  const path = window.location.pathname;

  return (
    <WSQueries
      queries={{ qUserProfile, qMemberProfile, qActivities, qPaymentsStatus }}
      onError={queries => {
        const failedQueries = Object.values(queries).filter(v => v.isError);
        const errors = failedQueries.map(value => value.error);
        sendMessageToParent("errorLoadingMemberData", { errors });
      }}
    >
      {({
        qActivities: { data: activities },
        qPaymentsStatus: { data: paymentsStatus }
      }) => {
        const {
          flows: { paymentsSetup: paymentsSetupActivity }
        } = activities;

        if (isInitialRedirect) {
          setIsInitialRedirect(false);
          if (path === "/onboarding") {
            if (paymentsSetupActivity) {
              // override if we wanna force W9 verification
              if (paymentsSetupActivity.complete && !skipTaxVerification) {
                history.push("/onboarding/payments/setup/business");
              } else if (!paymentsSetupActivity.complete) {
                history.push("/onboarding/payments/setup");
              } else {
                history.push("/onboarding/complete");
              }
            } else {
              history.push("/onboarding/payments/setup");
            }
          }
        }

        return (
          <>
            <Switch>
              <Route
                path="/settings/edit-payouts"
                render={({ match: { url } }) => (
                  <SetupPayoutSettings
                    basePath={url}
                    params={{
                      availablePayoutMethods
                    }}
                  />
                )}
              />
              <Redirect
                from="/member/settings/payment-methods"
                to="/settings/edit-payouts"
              />
              <Route
                path="/onboarding/complete"
                exact
                component={OnboarderComplete}
              />
              <Route
                path="/onboarding/payments/setup"
                render={() => (
                  <PaymentsSetup
                    basePath="/onboarding/payments/setup"
                    onBack={() => {}}
                    onFinish={() => {
                      history.push("/onboarding/complete");
                      sendMessageToParent("onboardingComplete");
                    }}
                    params={{
                      skipTaxVerification,
                      availablePayoutMethods
                    }}
                  />
                )}
              />
            </Switch>
            <MemberPlaidModals />
            <OptVerification />
            <ModalPayoutAccounts />
          </>
        );
      }}
    </WSQueries>
  );
};
