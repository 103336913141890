import { PayoutMethodType } from "../types/payments";

export const selectorPayoutMethodsFromQueryParam = (queryParam: string) => {
  const payoutMethods: PayoutMethodType[] = [];
  const paramValues = queryParam.split(",");

  paramValues.forEach(value => {
    if (value === "ach") {
      payoutMethods.push(PayoutMethodType.ACH);
    } else if (value === "instant") {
      payoutMethods.push(PayoutMethodType.Instant);
    } else if (value === "wallet") {
      payoutMethods.push(PayoutMethodType.Wallet);
    } else if (value === "giftcard") {
      payoutMethods.push(PayoutMethodType.GiftCard);
    }
  });

  return payoutMethods;
};
