import { WSButton, WSText } from "@wingspanhq/fe-component-library";
import React, { useState } from "react";
import { FormInputOnboardingOption } from "../../../../shared/components/FormInputOnboardingOption";
import { LayoutPaymentsSetup } from "../../../../shared/components/LayoutPaymentsSetup";
import { useLinkPayoutAccount } from "../../../../shared/hooks/useLinkPayoutAccount";
import { ACHConnectType } from "../../../../shared/types/payments";
import { RouteComponentProps } from "../../types";

export const RouteConnectBank: React.FC<RouteComponentProps> = ({
  onBack,
  onNext
}) => {
  const [achConnectType, setAchConnectType] = useState<
    ACHConnectType | undefined
  >();
  const { linkPayoutAccount, createAccountMeta } = useLinkPayoutAccount({
    onSuccess: () => {
      onNext({ achConnectType: ACHConnectType.PlaidLink });
    }
  });

  return (
    <LayoutPaymentsSetup onBack={onBack} title="Connect your bank account">
      <FormInputOnboardingOption
        mb="M"
        name="connectType"
        value={ACHConnectType.PlaidLink}
        checked={achConnectType === ACHConnectType.PlaidLink}
        onChange={setAchConnectType}
        title="Connect Instantly"
      >
        <WSText>
          Log in with your bank account information and get paid with a direct
          bank transfer (ACH)
        </WSText>
      </FormInputOnboardingOption>

      <FormInputOnboardingOption
        mb="M"
        name="connectType"
        value={ACHConnectType.Manual}
        checked={achConnectType === ACHConnectType.Manual}
        onChange={setAchConnectType}
        title="Enter your bank account details"
      >
        <WSText>Manually enter your routing and account numbers</WSText>
      </FormInputOnboardingOption>

      <WSButton
        mt="XL"
        name="continue"
        onClick={() => {
          if (achConnectType === ACHConnectType.Manual) {
            onNext({ achConnectType: ACHConnectType.Manual });
          } else {
            linkPayoutAccount();
          }
        }}
        loading={createAccountMeta.isLoading}
        disabled={!achConnectType}
        fullWidth
      >
        Continue
      </WSButton>

      <WSText.ParagraphSm mt="XL" color="gray500">
        Wingspan partners with Plaid to verify your bank account. Plaid is
        certified in internationally-recognized security standards, like ISO
        27001, ISO 27701, and is SSAE18 SOC 2 compliant.
      </WSText.ParagraphSm>
    </LayoutPaymentsSetup>
  );
};
