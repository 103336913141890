import {
  PayoutDestinationType,
  PayoutPreferences
} from "@wingspanhq/payments/dist/interfaces";
import { MutationConfig } from "react-query";
import { useWSMutation } from "../../../../query/helpers";
import { useUserId } from "../../../../query/hooks/helpers";
import { QUERY_PAYOUT_SETTINGS } from "../../../../query/payments/keys";
import { paymentsService } from "../../../../services/payments";
import { PayoutMethodType } from "../../../../shared/types/payments";
import { WSServiceError } from "../../../../utils/serviceHelper";

export const useMutationSubmit = (
  config: MutationConfig<{ selectedPayoutMethod: PayoutMethodType }>
) => {
  const userId = useUserId();

  return useWSMutation<
    { selectedPayoutMethod: PayoutMethodType; paymentCardId?: string },
    WSServiceError,
    { selectedPayoutMethod?: PayoutMethodType; paymentCardId?: string }
  >(
    async ({ selectedPayoutMethod, paymentCardId }) => {
      if (!selectedPayoutMethod) {
        throw new Error("No payout method selected");
      }

      if (selectedPayoutMethod === PayoutMethodType.Wallet) {
        await paymentsService.payoutSettings.update(userId, {
          payoutPreferences: PayoutPreferences.Standard,
          standard: null
        });
      } else if (selectedPayoutMethod === PayoutMethodType.GiftCard) {
        await paymentsService.payoutSettings.update(userId, {
          payoutPreferences: PayoutPreferences.Standard,
          standard: {
            destinationType: PayoutDestinationType.WeGift,
            destinationId: "WeGift"
          }
        });
      } else if (
        selectedPayoutMethod === PayoutMethodType.Instant &&
        paymentCardId
      ) {
        await paymentsService.payoutSettings.update(userId, {
          payoutPreferences: PayoutPreferences.Instant,
          standard: {
            destinationType: PayoutDestinationType.Card,
            destinationId: paymentCardId
          }
        });
      }

      return { selectedPayoutMethod, paymentCardId };
    },
    {
      dependencies: [QUERY_PAYOUT_SETTINGS],
      ...config
    }
  );
};
