import React from "react";
import { FormInternationalPayoutSettings } from "../../../../shared/components/FormInternationalPayoutSettings";
import { LayoutPaymentsSetup } from "../../../../shared/components/LayoutPaymentsSetup";
import { RouteComponentProps } from "../../types";

export const RouteInternational: React.FC<RouteComponentProps> = ({
  onBack,
  onNext
}) => {
  return (
    <LayoutPaymentsSetup onBack={onBack}>
      <FormInternationalPayoutSettings onSuccess={onNext} />
    </LayoutPaymentsSetup>
  );
};
