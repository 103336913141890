import React from "react";
import { LayoutPaymentsSetup } from "../../../../shared/components/LayoutPaymentsSetup";
import { RouteComponentProps } from "../../types";

export const RouteSuccess: React.FC<RouteComponentProps> = () => {
  return (
    <LayoutPaymentsSetup
      title="Done!"
      description="You successfully updated payout method"
    ></LayoutPaymentsSetup>
  );
};
