import { WSButton, WSText } from "@wingspanhq/fe-component-library";
import {
  PayoutDestinationType,
  PayoutPreferences
} from "@wingspanhq/payments/dist/interfaces";
import React, { useState } from "react";
import { OverlaySpinner } from "../../../../components/OverlaySpinner";
import { WSQueries } from "../../../../query/WSQuery";
import { useWSMutation } from "../../../../query/helpers";
import { useUserId } from "../../../../query/hooks/helpers";
import { QUERY_PAYOUT_SETTINGS } from "../../../../query/payments/keys";
import { usePayoutSettings } from "../../../../query/payments/queries";
import { paymentsService } from "../../../../services/payments";
import { BankAccount } from "../../../../shared/components/BankAccount";
import { FormInputOnboardingOption } from "../../../../shared/components/FormInputOnboardingOption";
import { LayoutPaymentsSetup } from "../../../../shared/components/LayoutPaymentsSetup";
import { WSServiceError } from "../../../../utils/serviceHelper";
import { RouteComponentProps } from "../../types";

export const RouteStandard: React.FC<RouteComponentProps> = ({
  onBack,
  onNext
}) => {
  const userId = useUserId();
  const payoutSettingsQuery = usePayoutSettings(userId);

  const [accountType, setAccountType] = useState<"existing" | "new">(
    "existing"
  );

  const [submit, submitMeta] = useWSMutation<any, WSServiceError>(
    async () => {
      if (accountType === "existing") {
        const accountDestination = payoutSettingsQuery.data?.payoutDestinations.find(
          d => d.destinationType === PayoutDestinationType.Account
        );

        if (accountDestination) {
          return await paymentsService.payoutSettings.update(userId, {
            payoutPreferences: PayoutPreferences.Standard,
            standard: {
              destinationId: accountDestination?.destinationId,
              destinationType: PayoutDestinationType.Account
            }
          });
        }
      }
      return { accountType };
    },
    {
      dependencies: [QUERY_PAYOUT_SETTINGS],
      onSuccess: onNext
    }
  );

  return (
    <LayoutPaymentsSetup
      title="Standard payouts to bank account"
      onBack={onBack}
    >
      <WSQueries queries={{ payoutSettingsQuery }}>
        {({ payoutSettingsQuery: { data: payoutSettings } }) => {
          const accountDestination = payoutSettings.payoutDestinations.find(
            d => d.destinationType === PayoutDestinationType.Account
          );

          return (
            <>
              <FormInputOnboardingOption
                mb="M"
                name="accountType"
                value="existing"
                checked={accountType === "existing"}
                onChange={setAccountType}
              >
                {accountDestination?.destinationId && (
                  <BankAccount accountId={accountDestination?.destinationId} />
                )}
              </FormInputOnboardingOption>

              <FormInputOnboardingOption
                mb="M"
                name="accountType"
                value="new"
                checked={accountType === "new"}
                onChange={setAccountType}
                title="Add a new bank account"
              >
                <WSText>Connect another bank account</WSText>
              </FormInputOnboardingOption>

              <WSButton
                mb="XL"
                name="continue"
                onClick={submit}
                loading={submitMeta.isLoading}
                fullWidth
              >
                Continue
              </WSButton>

              {submitMeta.isLoading && <OverlaySpinner />}
            </>
          );
        }}
      </WSQueries>
    </LayoutPaymentsSetup>
  );
};
