import { PayoutDestinationType } from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { useHistory } from "react-router-dom";
import { WSQueries } from "../../query/WSQuery";
import { useUserId } from "../../query/hooks/helpers";
import { useFeatureFlags } from "../../query/hooks/useFeatureFlags";
import { usePayoutSettings } from "../../query/payments/queries";
import { FlowSetup } from "../../shared/components/FlowSetup";
import { ACHConnectType, PayoutMethodType } from "../../shared/types/payments";
import { RouteConnectBank } from "./routes/RouteConnectBank";
import { RouteConnectBankManual } from "./routes/RouteConnectBankManual";
import { RouteInstant } from "./routes/RouteInstant";
import { RouteInstantNewCard } from "./routes/RouteInstantNewCard";
import { RouteInternational } from "./routes/RouteInternational";
import { RouteStandard } from "./routes/RouteStandard";
import { RouteSuccess } from "./routes/RouteSuccess";
import { RouteType } from "./routes/RouteType";
import { SetupParams } from "./types";

type Props = {
  basePath: string;
  params: SetupParams;
};

export const SetupPayoutSettings: React.FC<Props> = ({ basePath, params }) => {
  const history = useHistory();
  const userId = useUserId();
  const queryPayoutSettings = usePayoutSettings(userId);
  const queryFeatureFlags = useFeatureFlags();

  return (
    <WSQueries queries={{ queryPayoutSettings, queryFeatureFlags }}>
      {({
        queryPayoutSettings: { data: payoutSettings },
        queryFeatureFlags: { data: featureFlags }
      }) => (
        <FlowSetup
          basePath={basePath}
          params={params}
          routes={[
            {
              path: "/type",
              component: RouteType,
              onNext: (context?: {
                selectedPayoutMethod?: PayoutMethodType;
              }) => {
                if (
                  context?.selectedPayoutMethod === PayoutMethodType.Instant
                ) {
                  const cardDestination = payoutSettings.payoutDestinations.find(
                    d => d.destinationType === PayoutDestinationType.Card
                  );
                  if (cardDestination) {
                    history.push(basePath + "/instant");
                  } else {
                    if (featureFlags.paymentCards) {
                      history.push(basePath + "/success");
                    } else {
                      history.push(basePath + "/instant-new-card");
                    }
                  }
                } else if (
                  context?.selectedPayoutMethod === PayoutMethodType.ACH
                ) {
                  const accountDestination = payoutSettings.payoutDestinations.find(
                    d => d.destinationType === PayoutDestinationType.Account
                  );
                  if (accountDestination) {
                    history.push(basePath + "/standard");
                  } else {
                    history.push(basePath + "/connect-bank");
                  }
                } else {
                  history.push(basePath + "/success");
                }
              }
            },
            {
              path: "/instant",
              component: RouteInstant,
              onBack: () => {
                history.push(basePath + "/type");
              },
              onNext: context => {
                if (context?.debitCardType === "new") {
                  if (featureFlags.paymentCards) {
                    history.push(basePath + "/success");
                  } else {
                    history.push(basePath + "/instant-new-card");
                  }
                } else {
                  history.push(basePath + "/success");
                }
              }
            },
            {
              path: "/instant-new-card",
              component: RouteInstantNewCard,
              onBack: () => {
                history.push(basePath + "/type");
              },
              onNext: () => {
                history.push(basePath + "/success");
              }
            },

            {
              path: "/standard",
              component: RouteStandard,
              onBack: () => {
                history.push(basePath + "/type");
              },
              onNext: context => {
                if (context?.accountType === "new") {
                  history.push(basePath + "/connect-bank");
                } else {
                  history.push(basePath + "/success");
                }
              }
            },
            {
              path: "/connect-bank",
              component: RouteConnectBank,
              onBack: () => {
                history.push(basePath + "/type");
              },
              onNext: context => {
                if (context?.achConnectType === ACHConnectType.Manual) {
                  history.push(basePath + "/connect-bank-manually");
                } else {
                  history.push(basePath + "/success");
                }
              }
            },
            {
              path: "/connect-bank-manually",
              component: RouteConnectBankManual,
              onBack: () => {
                history.push(basePath + "/connect-bank");
              },
              onNext: () => {
                history.push(basePath + "/success");
              }
            },
            {
              path: "/international",
              component: RouteInternational,
              onNext: () => {
                history.push(basePath + "/success");
              }
            },
            {
              path: "/success",
              component: RouteSuccess,
              onNext: () => {}
            }
          ]}
        />
      )}
    </WSQueries>
  );
};
